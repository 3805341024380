import React from 'react';
import Fade from 'react-reveal/Fade';

function Data(props) {
    return (
        <div className="justify-content-center">
            <Fade left>
                <div className="col-0 ">
                    <div>
                        <div className="data-header col-12">
                            <h3 className="extra-title">ORGANISMOS, DATOS Y DIRECCIONES ÚTILES</h3>
                        </div>
                        <hr/>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12 data-place">SECRETARÍA DE EXTENSIÓN</div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="container justify-content-center">
                                <hr />
                                <h5 className="ref-heavy">Referente Fabián Melo</h5>
                                <h5 className="data-contact">
                                    Yrigoyen 662 1er piso,
                                    Tel. 0249 4444555 int. 1555
                                </h5>
                                <hr/>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12 data-place">SECRETARÍA DE
                                    BIENESTAR ESTUDIANTIL</div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr/>
                            <h5 className="data-info" >Departamento de Trabajo
                            Social, De. de Salud,
                            Programa de Becas
                            Residencias Universitarias.
                            </h5>
                            <hr/>
                            <h5 className="ref-heavy">Referente Sandra Pantusa</h5>
                            <h5 className="data-contact">
                                Gimnasio Campus
                                Universitario Tandil,
                                Tel. 0249 4385510
                            </h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12 data-place">SECRETARÍA ACADÉMICA</div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="data-info">Asesoramiento
                            </h5>
                            <hr />
                            <h5 className="ref-heavy">Referente Laura Iglesias</h5>
                            <h5 className="data-contact">
                                Pinto 399, 2do piso Rectorado.
                                Tel. 0249 4422000 Int. 118
                            </h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-facultad">FACULTADES</h4>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">AGRONOMÍA</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Laura Aguas</h5>
                            <h5 className="data-contact">Tel. 02281 433291 / 433292 / 433293</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">ARTE</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Carolina Cesario</h5>
                            <h5 className="data-contact">Tel. 0249 4440631</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">ECONÓMICAS</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Moisés Bueno</h5>
                            <h5 className="data-contact">Tel. 0249 4385550</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">EXACTAS</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Mariel Contreras</h5>
                            <h5 className="data-contact">Tel. 0249 4385650</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">DERECHO</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Esteban Marmeto</h5>
                            <h5 className="data-contact">Tel. 02281 425353</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">HUMANAS</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Graciela Sánchez</h5>
                            <h5 className="data-contact">Tel. 0249 4385750</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">INGENIERÍA</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Néstor Ferreira</h5>
                            <h5 className="data-contact">Tel. 02284 451055</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">SALUD</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Nancy Cardoso</h5>
                            <h5 className="data-contact">Tel. 02284 426382</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">SOCIALES</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Gabriela Delia</h5>
                            <h5 className="data-contact">Tel. 02284 45.0331</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">VETERINARIAS</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente Claudia Pingitore</h5>
                            <h5 className="data-contact">Tel. 0249 4385850</h5>
                            <hr />
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">UNIDAD DE ENSEÑANZA QUEQUÉN</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr />
                            <h5 className="ref-heavy">Referente María José Arena</h5>
                            <h5 className="data-contact">Tel. 02262 453232</h5>
                            <hr />
                        </div>
                    </div>
                </div>
            </Fade>
        </div>

    );
}

export default Data;
