import React from 'react';
import Fade from 'react-reveal/Fade';

function Links(props) {
    return (
        <div className="justify-content-center">
            <Fade left>
                <div className="col-0 ">
                    <div>
                        <div className="data-header col-12">
                            <h3 className="extra-title">LINKS DE INTERES</h3>
                        </div>
                        <hr/>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">DOCUMENTOS RID-CIN</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center px-5">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href={`${process.env.PUBLIC_URL}/assets/docs/Acuerdo-P.-1104-20.pdf`}>Programa integral de accesibilidad para las Universidades Públicas</a></h5>
                        </div>
                        <div className="container justify-content-center px-5">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href={`${process.env.PUBLIC_URL}/assets/docs/RID-RUEDA_Accesible.pdf`}>Disponibilidad e implementación de estrategias de accesibilidad y ajustes razonables, en los sistemas de educación a distancia (SIED) implementados por las instituciones universitarias nacionales y provinciales</a></h5>
                        </div>
                        <div className="container justify-content-center px-5">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://rid.cin.edu.ar/institucional'>Link de acceso a la página de la Red Interuniversitaria de Discapacidad</a></h5>
                            <hr/>
                        </div>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12 data-place">GUÍAS</div>
                            </div>
                        </div>
                        <div class="list-links-interes" className="col-12 ">
                            <div className="container justify-content-center px-5">
                                <hr/>
                                <h5 className="ref-heavy title-link"><a target='BLANK_' href='https://docentes.unahur.edu.ar/wp-content/uploads/2021/10/Guia-de-recomendaciones-de-accesibilidad.pdf'> Guía de recomendaciones para la accesibilidad académica para docentes de la Universidad Nacional de Urlinghan </a></h5>
                            </div>
                        </div>

                        <div className="container justify-content-center px-5">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://www.unc.edu.ar/sites/default/files/GUIA%20DE%20Recomendaciones%20DOCENTES%20UNC.pdf '>Guía de recomendaciones para un cursado virtual accesible de la Universidad Nacional de Córdoba</a></h5>
                        </div>

                        <div className="container justify-content-center px-5">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://www.uncuyo.edu.ar/programa_discapacidad/guia-sobre-material-accesible-para-estudiantes-con-discapacidad-de-la-uncuyo'>Guía sobre material accesible para estudiantes con Discapacidad de la Universidad Nacional de Cuyo</a></h5>
                        </div>


                        <div className="container justify-content-center">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='http://humanas.unsl.edu.ar/accesibilidad/archivos/Guia_Buenas_Practicas-Discapacidad.pdf'>Guía de Buenas Prácticas para la Atención de Personas con Discapacidad en la Universidad Nacional de Quilmes</a></h5>
                        </div>

                        <div className="container justify-content-center">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://fcpolit.unr.edu.ar/wp-content/uploads/2020/06/Gu%C3%ADa-de-accesibilizaci%C3%B3n-de-documentos-digitales.pdf'>Guía de recomendaciones para la accesibilización de textos digitales de la Facultad de Ciencias Políticas y Relaciones Internacionales de la Universidad Nacional de Rosario</a></h5>
                        </div>

                        <div className="container justify-content-center">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://web.unican.es/unidades/soucan/PublishingImages/estudiantes/Gu%C3%ADa%20de%20accesibilidad%20UC.pdf  '>Guía de accesibilidad de la Universidad de Cantabria</a></h5>
                        </div>

                        <div className="container justify-content-center">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://web.ua.es/es/accesibilidad/documentos-electronicos-accesibles.html'>Guía de accesibilidad digital de la Universidad de Alicante</a></h5>
                        </div>

                        <div className="container justify-content-center">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://www.cud.unlp.edu.ar/uploads/docs/consideraciones_generales_para_la_inclusion_de_personas_con_discapacidad_en_la_universidad.pdf'>Consideraciones generales para la inclusión de personas con discapacidad en la Facultad de Ciencias Económicas de la Universidad Nacional de La Plata</a></h5>
                        </div>

                        <div className="container justify-content-center">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://n9.cl/0g1z'>Manual práctico para hacer textos accesibles para estudiantes con diversidad funcional de la Universidad Complutense de Madrid</a></h5>
                            <hr/>
                        </div>

                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-12">
                                    <h4 className="data-place">CURSOS</h4>
                                </div>
                            </div>
                        </div>
                        <div className="container justify-content-center">
                            <hr/>
                            <h5 className="ref-heavy"><a target='BLANK_' href='https://www.josemariafernandez.es/archivos/swing-accessible-documents/swing_esp.html '>Curso de Formación sobre documentos accesibles de la Universidad de Alicante</a></h5>
                            <hr/>
                        </div>

                        <h5 class="links-sugerencias">Podes sugerirnos nuevos materiales en el mail: <span >extension@rec.unicen.edu.ar</span></h5>
                    </div>
                </div>
            </Fade>
        </div>

    );
}

export default Links;
