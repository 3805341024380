import React from 'react';

function Nineth() {

    return(
        <div className="global-div-component">
            <div className="row">
                <div className="col-12">
                    <img className="img-height-55"
                         src={`${process.env.PUBLIC_URL}/assets/images/image_visceral.png`}
                         alt="Imagen Discapacidad Visceral"/>
                </div>
            </div>
        </div>
    );
}


export default Nineth;


